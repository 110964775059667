import React, { useCallback, useState } from "react";
import {
  EuiToolTip,
  EuiButtonGroupProps,
  EuiButtonGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiTextBlockTruncate,
  EuiHorizontalRule,
} from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";
import { differenceInHours, formatDistanceToNow } from "date-fns";

import { PmDataTitle } from "@pm-frontend/shared/components/DetailSection/PmDataTitle";
import { colors } from "@pm-frontend/styles";
import { PmCard, PmCardProps } from "@pm-frontend/shared/components/Card/PmCard";
import { SchedulableMeldListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_list_view_serializer";
import {
  getMeldCoordinates,
  getMeldPriorityBadgeProps,
  getMeldStatusBadgeProps,
} from "@pm-frontend/shared/utils/meld-utils";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import { formatTimeHHMM_xm } from "@pm-frontend/shared/utils/formatDayMonth";
import { useGetCalendarMeldsList } from "../queries";
import { PmCallout } from "@pm-frontend/shared/components/PmCallout";
import { PmPageLoading } from "@pm-frontend/shared/components/Loaders/PmPageLoading";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { MeldFilterGroupListViewSerializer } from "@pm-frontend/shared/types/api/saved_filters/serializers/meld_filter_group_list_view_serializer";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { DraggingEvent, getCalendarEventOnDragStart } from "../utils/click-drag-and-drop-utils";
import { CalendarMapMarkerType } from "../maps/utils";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { PmListFilters } from "@pm-frontend/shared/components/FilterButtons/PmListFilters";
import {
  getDefaultMeldFilters,
  getMeldSavedFilterConfig,
} from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { BaseSavedFiltersFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import { useGetAllMaintenance } from "@pm-frontend/shared/hooks/queries/useGetAllMaintenance";
import { useGetMeldFilterGroups } from "@pm-frontend/routes/Melds/MeldList/queries";
import { useGetCalendarEventMetaData, useGetSetActivePaneAndMap } from "../utils/hooks";
import { CalendarMeldMapNearbyMeldOpened, track } from "@pm-app/utils/analytics";
import { isMaintenanceManagementAgent, isValidCompositeId } from "@pm-frontend/shared/utils/assignment-utils";
import { getPropertyNameLine1 } from "@pm-frontend/shared/utils/property-utils";
import { getPropertyNameDisplayStreetAndUnit } from "@pm-frontend/shared/utils/unit-utils";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
import { PmInfoIcon } from "@pm-frontend/shared/components/PmInfoIcon";
import URL from "@pm-shared/utils/url";
import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";
import {
  useCalendarMeldFilterParamsStateActions,
  useCalendarStateMeldFilterApplyOnClick,
  useCalendarStateMeldFilterParams,
} from "../stores/meldFilterParamStore";
import { useCalendarDragAndDropStateActions } from "../stores/dragDropStore";
import { MeldPriority, MeldStatus, OpenStatuses } from "@pm-frontend/shared/types/meld";
import { isInPast } from "@pm-frontend/shared/utils/calendar-utils";
import { PmIconBadge } from "@pm-frontend/shared/components/PmIconBadge";
import { AggregatedCalendarEvent } from "../utils/aggregated-calendar-events-utils";
import { SchedulableMeldDetailViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_detail_view_serializer";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";
import { getCalendarStateActions } from "../stores/calendarStateStore";
import {
  CalendarDraftPendingActions,
  useCalendarDraftModeEnabled,
  useGetCalendarDraftModePendingActions,
} from "../stores/draftModeStore";
import { getCalendarAssignedMaintenance } from "../utils/utils";

// just a utility type for grabbing two specific variants of AggregatedCalendarEvent union
type ExtractMember<U, T extends U[keyof U]> = U extends { type: T } ? U : never;

type MeldCalendarDetailsCardProps =
  | {
      source: "meldsList";
      meld: SchedulableMeldListViewSerializer;
      appliedQuickFilter: MeldCalendarListQuickFilterOptions | null;
    }
  | {
      source: "mapEventList";
      event:
        | ExtractMember<CalendarMapMarkerType, "management_scheduled">
        | ExtractMember<CalendarMapMarkerType, "vendor_scheduled">
        | ExtractMember<CalendarMapMarkerType, "nearby_melds">;
      meld:
        | ExtractMember<CalendarMapMarkerType, "management_scheduled">["meld"]
        | ExtractMember<CalendarMapMarkerType, "vendor_scheduled">["meld"]
        | ExtractMember<CalendarMapMarkerType, "nearby_melds">["meld"];
    }
  | {
      source: "calendarEventHover";
      meld:
        | ExtractMember<AggregatedCalendarEvent, "management_scheduled">["meld"]
        | ExtractMember<AggregatedCalendarEvent, "vendor_scheduled">["meld"]
        | SchedulableMeldDetailViewSerializer;
    };

const CircleWithNumber = ({ number, color }: { number: number; color: string }) => {
  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: color,
    color: colors.neutrals.gray900,
    fontSize: "12px",
  };

  return <div style={style}>{number || ""}</div>;
};

const getAddressText = (meld: MeldCalendarDetailsCardProps["meld"]): string => {
  // some TS issues led me to doing prop/unit checking manually
  if (meld.prop) {
    return getPropertyNameLine1(meld.prop);
  } else if (meld.unit) {
    return getPropertyNameDisplayStreetAndUnit(meld.unit);
  } else {
    return "";
  }
};

function getAssignees(
  props: MeldCalendarDetailsCardProps,
  draftModeEnabled: boolean,
  pendingDraftActions: CalendarDraftPendingActions[]
): Array<{ name: string; color: string; key: string }> {
  if (props.source === "meldsList") {
    const assignees = getCalendarAssignedMaintenance(props.meld, draftModeEnabled, pendingDraftActions);
    switch (assignees?.type) {
      case "ManagementAgent":
        return assignees.in_house_servicers.map((agent) => ({
          name: getFullName(agent),
          key: `agent-${agent.id}`,
          color: getPersonaColor(agent),
        }));
      case "Vendor":
        return [
          { name: assignees.name, key: `vendor-${assignees.vendor.id}`, color: getPersonaColor(assignees.vendor) },
        ];
      default:
        return [];
    }
  } else if (props.source === "mapEventList") {
    // markers not associated with a persona have an undefined name
    return props.event.personas
      .filter((p) => !!p.name)
      .map((p) => {
        return {
          name: p.name || "",
          key: p.name + p.color,
          color: p.color,
        };
      });
  } else {
    return [];
  }
}

/**
 * Get the list of conditions for why the Meld was included in the
 * 'Review' filter
 * Needs to match `meld/api/api.py:CalendarMeldListViewReviewQuickFilter`
 * We have ordered the conditions according to which reasons we want to show
 * up first
 *
 * the `last_scheduled_end` is a field generated by an annotation only for this endpoint
 * it represents the ending time of the latest appointment on the Meld
 */
const getQuickFilterReasons = (
  meld: SchedulableMeldListViewSerializer & { last_scheduled_end?: string | undefined }
): string[] => {
  const meldInOpenStatus = OpenStatuses.includes(meld.status) && meld.status !== MeldStatus.PENDING_ESTIMATES;
  const now = new Date();
  // CONDITION 2
  // high priority, open (not pending estimates), not scheduled, created at least 48 hours ago
  if (
    meld.priority === MeldPriority.HIGH &&
    meldInOpenStatus &&
    differenceInHours(now, new Date(meld.created)) > 48 &&
    !meld.last_scheduled_end
  ) {
    return ["High priority", "Not scheduled", "Created more than 2 days ago"];
  }

  // CONDITION 3
  // medium priority, open, not scheduled, created at least 5d (120 hours) ago
  if (
    meld.priority === MeldPriority.MEDIUM &&
    meldInOpenStatus &&
    differenceInHours(now, new Date(meld.created)) > 120 &&
    !meld.last_scheduled_end
  ) {
    return ["Medium priority", "Not scheduled", "Created more than 5 days ago"];
  }

  // CONDITION 1
  // Latest scheduled date is before 24 hours ago and Meld is open (not pending estimates)
  if (meldInOpenStatus && meld.last_scheduled_end && differenceInHours(now, new Date(meld.last_scheduled_end)) > 24) {
    return ["More than a day past scheduled date"];
  }

  // CONDITION 4
  // open (not pending estimates), hasn't been updated in three days (72 hours) and is not scheduled in the future
  if (
    meldInOpenStatus &&
    differenceInHours(now, new Date(meld.updated)) > 72 &&
    (!meld.last_scheduled_end || isInPast(meld.last_scheduled_end))
  ) {
    return ["No updates in at least 3 days", "No upcoming appointments"];
  }
  // shouldn't occur, but not strictly prevented
  return ["This Meld needs your attention"];
};

const QuickFilterReasons = ({ meld }: { meld: SchedulableMeldListViewSerializer }) => {
  const reasons = getQuickFilterReasons(meld);
  return (
    <EuiToolTip
      content={
        <div style={{ marginLeft: "4px" }}>
          <strong>Why a review is recommended</strong>
          <EuiHorizontalRule margin="xs" />
          <ul style={{ listStyle: "disc", paddingLeft: "16px" }}>
            {reasons.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </div>
      }
    >
      <EuiFlexGroup
        direction="row"
        responsive={false}
        gutterSize="xs"
        alignItems="center"
        style={{ background: colors.brand.veryLightBlue, padding: "0px 4px", borderRadius: "2px" }}
      >
        <EuiFlexItem grow={false} style={{ width: "16px", padding: "1px" }}>
          <EuiIcon type={URL.getStatic("icons/network_intelligence_gray.svg")} size="original" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <PmText fontSize="p3">Review recommended</PmText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiToolTip>
  );
};

const AssigneeBadge = ({ assignees }: { assignees: ReturnType<typeof getAssignees> }) => {
  return (
    <>
      {assignees.map((assignee) => (
        <EuiFlexItem grow={false} key={assignee.key}>
          <PmBadge bgColor={assignee.color} textColor={colors.neutrals.gray900} text={assignee.name} />
        </EuiFlexItem>
      ))}
    </>
  );
};

const MeldCalendarDetailsCard = (props: MeldCalendarDetailsCardProps) => {
  const eventMetaData = useGetCalendarEventMetaData();
  const { calendarOrMap, rightPaneState, updateRightPaneURL } = useGetSetActivePaneAndMap();
  const history = useHistory();
  const location = useLocation();

  const draftModeEnabled = useCalendarDraftModeEnabled();
  const pendingDraftActions = useGetCalendarDraftModePendingActions();

  const onCardClick = () => {
    const searchParams = new URLSearchParams(location.search);

    if (props.source === "mapEventList" && props.event.type === "nearby_melds") {
      if (calendarOrMap === "large_map" || isValidCompositeId(calendarOrMap)) {
        track(
          CalendarMeldMapNearbyMeldOpened({
            type:
              calendarOrMap === "large_map"
                ? "large"
                : isMaintenanceManagementAgent({ composite_id: calendarOrMap })
                ? "small-agent"
                : "small-vendor",
            ...eventMetaData,
          })
        );
      }
    }

    if (rightPaneState.type === "agentMeldsList") {
      updateRightPaneURL({
        newRightpaneState: {
          type: "agentMelds_details",
          agentId: rightPaneState.agentId,
          meldId: props.meld.id.toString(),
        },
      });
    } else if (rightPaneState.type === "vendorMeldsList") {
      updateRightPaneURL({
        newRightpaneState: {
          type: "vendorMelds_details",
          vendorId: rightPaneState.vendorId,
          meldId: props.meld.id.toString(),
        },
      });
    } else {
      history.push({
        pathname: LinkHelper.normalize(RouteUrls.meldCalendar(props.meld)),
        search: searchParams.toString(),
      });
    }
  };

  let personaText = "Property Meld";
  switch (props.meld.create_by?.org_type) {
    case "m":
      personaText = "Manager";
      break;
    case "t":
      personaText = "Resident";
      break;
    default:
      break;
  }

  const assignees = getAssignees(props, draftModeEnabled, pendingDraftActions);

  const panelStyles: PmCardProps["panelStyles"] = { overflow: "hidden" };
  if (props.source === "calendarEventHover") {
    panelStyles.padding = "12px";
  }

  const briefDescriptionText = (
    <div style={{ fontSize: "16px" }}>
      <EuiTextBlockTruncate lines={2}>{props.meld.brief_description}</EuiTextBlockTruncate>
    </div>
  );

  return (
    <div style={{ paddingBottom: "6px" }} data-testid={`meld-list-card-${props.meld.id}`}>
      <PmCard padding={calendarOrMap === "calendar" ? "12px 12px 12px 0px" : "12px"} panelStyles={panelStyles}>
        <EuiFlexGroup direction="row" responsive={false} gutterSize="xs" alignItems="center">
          {calendarOrMap === "calendar" && props.source !== "calendarEventHover" && (
            <EuiFlexItem grow={false}>
              <EuiIcon type="grabHorizontal" />
            </EuiFlexItem>
          )}
          <EuiFlexItem>
            <EuiFlexGroup direction="column" gutterSize="s" onClick={onCardClick} alignItems="flexStart">
              <EuiFlexItem grow={false}>
                {props.source === "calendarEventHover" ? (
                  briefDescriptionText
                ) : (
                  <EuiLink>{briefDescriptionText}</EuiLink>
                )}
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction="row" responsive={false} gutterSize="xs" alignItems="flexStart">
                  <EuiFlexItem grow={false} style={{ paddingTop: "2px" }}>
                    <EuiIcon type={URL.getStatic("icons/map_marker_empty.svg")} />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <PmText fontSize="p2" style={{ lineHeight: "20px" }}>
                      <EuiTextBlockTruncate lines={2}>{getAddressText(props.meld)}</EuiTextBlockTruncate>
                    </PmText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ lineHeight: "14px" }}>
                <PmText fontSize="p2">#{props.meld.reference_id}</PmText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction="row" gutterSize="s" style={{ flexWrap: "wrap" }}>
                  <EuiFlexItem grow={false}>
                    <PmBadge {...getMeldStatusBadgeProps(props.meld)} />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <PmBadge {...getMeldPriorityBadgeProps(props.meld)} />
                  </EuiFlexItem>
                  {assignees && <AssigneeBadge assignees={assignees} />}
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction="row" responsive={false} gutterSize="s" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <EuiIcon
                      type={URL.getStatic("icons/calendar_today_black.svg")}
                      style={{ cursor: "pointer", height: "16px", width: "16px" }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <PmText fontSize="p3" style={{ lineHeight: "1rem" }}>{`Created ${formatDistanceToNow(
                      new Date(props.meld.created),
                      { addSuffix: true }
                    )} by ${personaText}`}</PmText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              {props.meld.tenant_presence_required && (
                <EuiFlexItem grow={false}>
                  <PmIconBadge type={URL.getStatic("icons/residents_gray.svg")} badgeText="Resident Required" />
                </EuiFlexItem>
              )}
              {props.source === "meldsList" && props.appliedQuickFilter === "review" && (
                <EuiFlexItem grow={false}>
                  <QuickFilterReasons meld={props.meld} />
                </EuiFlexItem>
              )}
              {props.source === "mapEventList" && (
                <EuiFlexGroup direction="row" gutterSize="xs" alignItems="center">
                  {props.event.personas.map((persona) => (
                    <EuiFlexItem grow={false} key={persona.color + persona.count}>
                      <CircleWithNumber number={persona.count} color={persona.color} />
                    </EuiFlexItem>
                  ))}
                  {props.event.start && props.event.end && (
                    <EuiFlexItem grow={false}>
                      {`${formatTimeHHMM_xm(props.event.start)} - ${formatTimeHHMM_xm(props.event.end || "")}`}
                    </EuiFlexItem>
                  )}
                  {props.event.type === "nearby_melds" && <EuiFlexItem grow={false}>Nearby Meld</EuiFlexItem>}
                </EuiFlexGroup>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </PmCard>
    </div>
  );
};

const QuickFilterTooltipContent = () => {
  return (
    <div>
      <div>
        <strong>Dispatch</strong> - Unscheduled in-house Melds with the newest and highest priority first.
      </div>
      <EuiSpacer />
      <div>
        <strong>Review</strong> - Melds that need attention due to inactivity or age.
      </div>
      <EuiSpacer />
      <div>
        <strong>All Melds</strong> - Filterable list of all Melds with custom sorting.
      </div>
    </div>
  );
};

const options: EuiButtonGroupProps["options"] = [
  {
    id: "dispatch",
    label: "Dispatch",
  },
  {
    id: "review",
    label: "Review",
  },
  {
    id: "allmelds",
    label: "All Melds",
  },
];
const MeldCalendarMeldListQuickFilters = ({
  appliedQuickFilter,
  setAppliedQuickFilter,
}: {
  appliedQuickFilter: MeldCalendarListQuickFilterOptions;
  setAppliedQuickFilter: React.Dispatch<React.SetStateAction<MeldCalendarListQuickFilterOptions>>;
}) => {
  return (
    <EuiFlexGroup direction="row" responsive={false} alignItems="center" gutterSize="s">
      <EuiFlexItem grow={false} style={{ width: "100%" }}>
        <EuiButtonGroup
          options={options}
          idSelected={appliedQuickFilter}
          onChange={(id) => setAppliedQuickFilter(id as MeldCalendarListQuickFilterOptions)}
          legend="Apply a filter to the list of Melds"
          isFullWidth={true}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: "15px" }}>
        <PmInfoIcon
          tooltipProps={{ content: <QuickFilterTooltipContent />, repositionOnScroll: true }}
          iconAriaLabel="Quick filter explanations"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const MeldCalendarMeldsListFilterButtons = ({
  meldSavedFilterGroups,
  allMaint,
  savedFilter,
}: {
  meldSavedFilterGroups: MeldFilterGroupListViewSerializer;
  allMaint: AllVendorsListRegisteredOnlyFalse;
  savedFilter: MeldFilterGroupListViewSerializer[number] | undefined;
}) => {
  // the filter classes are persisted in the store so we don't have to recreate
  // them when switching back and forth between calendar and map
  const { populateMeldFilterParamsOptions } = useCalendarMeldFilterParamsStateActions();
  const onApplyAdditionalOnClick = useCalendarStateMeldFilterApplyOnClick();
  const meldFilterParams = useCalendarStateMeldFilterParams();

  // if the persisted filter params are readonly we replace them with classes
  // whose options are populated from the api calls. This should only occur once
  // per session as we set the type to 'read-write'
  const updateStoredMeldFilterParams = useCallback(() => {
    if (meldFilterParams.calendar.type === "read-only") {
      populateMeldFilterParamsOptions((state) => {
        const { filters: newFilters, sortFilter: newSortFilter } = getDefaultMeldFilters({
          allMaint,
          overrides: { sort: { sort: { alwaysShow: false } } },
          onApplyAdditionalOnClick,
        });
        const newSavedFilter = new BaseSavedFiltersFilterClass({
          config: getMeldSavedFilterConfig({
            savedFilters: meldSavedFilterGroups,
            otherFilters: [...newFilters, newSortFilter],
          }),
          overrides: { alwaysShow: true },
          onApplyAdditionalOnClick,
        });
        return {
          sortFilterClass: newSortFilter,
          savedFilterClass: newSavedFilter,
          filterClasses: newFilters,
          type: "read-write",
          priorParams: state.meldFilterParams.calendar.priorParams,
        };
      });
    }
  }, []);

  // savedFilter being undefined means
  // (a) buttons won't show if they match the selected saved filter and
  // (b) when transitioning from saved filter => manual we start from scratch instead of saved filter values
  return (
    <div ref={updateStoredMeldFilterParams}>
      <PmListFilters
        filters={meldFilterParams.calendar.filterClasses}
        savedFilter={savedFilter}
        onSaveClick={undefined}
        savedFiltersFilter={meldFilterParams.calendar.savedFilterClass}
        sortFilter={meldFilterParams.calendar.sortFilterClass}
      />
    </div>
  );
};

const QUICK_FILTER_OPTIONS = ["dispatch", "review", "allmelds"] as const;
type MeldCalendarListQuickFilterOptions = typeof QUICK_FILTER_OPTIONS[number];

const APPLIED_QUICK_FILTER_LOCAL_STORAGE_KEY = `calendar-meld-list-applied-quick-filter-user-${AuthUtils.getUserId()}-v1`;
const DEFAULT_QUICK_FILTER: MeldCalendarListQuickFilterOptions = "dispatch";

function isQuickFilterOption(value: string): value is MeldCalendarListQuickFilterOptions {
  return (QUICK_FILTER_OPTIONS as readonly string[]).includes(value);
}

function loadCalendarAppliedFilterFromStorage(): MeldCalendarListQuickFilterOptions {
  const { ok, value: rawValue } = StorageUtils.getLocalStorageItem(APPLIED_QUICK_FILTER_LOCAL_STORAGE_KEY);
  if (!ok) {
    return DEFAULT_QUICK_FILTER;
  } else if (!rawValue) {
    return DEFAULT_QUICK_FILTER;
  } else if (isQuickFilterOption(rawValue)) {
    return rawValue;
  } else {
    return DEFAULT_QUICK_FILTER;
  }
}

function storeAppliedFilterInLocalStorage(appliedQuickFilter: MeldCalendarListQuickFilterOptions) {
  StorageUtils.setLocalStorageItem(APPLIED_QUICK_FILTER_LOCAL_STORAGE_KEY, appliedQuickFilter);
}

const MeldCalendarMeldsList = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();
  const meldFilterParams = useCalendarStateMeldFilterParams();

  const [appliedQuickFilter, setAppliedQuickFilterState] = useState<MeldCalendarListQuickFilterOptions>(
    loadCalendarAppliedFilterFromStorage
  );

  const setAppliedQuickFilter = (newFilter: MeldCalendarListQuickFilterOptions) => {
    storeAppliedFilterInLocalStorage(newFilter);
    setAppliedQuickFilterState(newFilter);
  };

  const {
    data: meldsList,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
  } = useGetCalendarMeldsList({ meldFilterParams, appliedQuickFilter });

  const { startDraggingMeld, stopDragging } = useCalendarDragAndDropStateActions();
  const { data: allMaint } = useGetAllMaintenance();
  const { data: meldSavedFilters } = useGetMeldFilterGroups();

  const searchParams = new URLSearchParams(location.search);
  const savedFilterId = searchParams.get("saved_filter");
  const savedFilter = savedFilterId
    ? meldSavedFilters?.find((filter) => filter.id.toString() === savedFilterId)
    : undefined;

  if (isError) {
    return <PmCallout type="danger" message="Melds failed to load, try reloading the page" />;
  }
  const melds = meldsList?.pages.map((page) => page.results).flat() || [];

  const removeAppliedSavedFilterOnClick = () => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete("saved_filter");
    history.push({ pathname: location.pathname, search: newSearchParams.toString() });
  };

  return (
    <EuiFlexGrid style={{ gridTemplateColumns: "100%" }} gutterSize="m" data-testid="meld-calendar-meld-list-rightpane">
      {!isMobile && (
        <PmDataTitle text="Melds" headerComponent="h2" color={colors.neutrals.gray900} disableBottomSpacing={true} />
      )}
      <MeldCalendarMeldListQuickFilters
        appliedQuickFilter={appliedQuickFilter}
        setAppliedQuickFilter={setAppliedQuickFilter}
      />
      {appliedQuickFilter === "allmelds" && savedFilter && (
        <PmBadge
          text={savedFilter.name}
          bgColor={colors.brand.meldBlue}
          textColor={colors.brand.white}
          iconOnClickAriaLabel="Remove filter"
          iconOnClick={removeAppliedSavedFilterOnClick}
        />
      )}
      {appliedQuickFilter === "allmelds" && meldSavedFilters && allMaint && (
        <MeldCalendarMeldsListFilterButtons
          meldSavedFilterGroups={meldSavedFilters}
          allMaint={allMaint}
          savedFilter={savedFilter}
        />
      )}
      {melds.length === 0 && !isLoading && <PmCallout message="No Melds match your filter" />}
      {isLoading && <PmPageLoading />}
      {melds.map((meld) => {
        return (
          <EuiFlexItem
            grow={false}
            key={meld.id}
            onDragStart={getCalendarEventOnDragStart({
              meld,
              coordinates: getMeldCoordinates(meld),
              additionalCallback: (id) => {
                startDraggingMeld(id);
                getCalendarStateActions().setResidentAvailabilityMeldId(meld.id);
              },
              appointmentId: null,
              type: DraggingEvent.meld,
              personaType: null,
              personaIds: meld.in_house_servicers.map((servicer) => servicer.agent.id),
            })}
            onDragEnd={() => {
              stopDragging();
              getCalendarStateActions().setResidentAvailabilityMeldId(null);
            }}
            draggable={true}
            style={{ cursor: "grab" }}
          >
            <MeldCalendarDetailsCard meld={meld} source="meldsList" appliedQuickFilter={appliedQuickFilter} />
          </EuiFlexItem>
        );
      })}
      {hasNextPage && <PmEmptyButton text="View More" onClick={fetchNextPage} hasBorder={false} />}
    </EuiFlexGrid>
  );
};

export {
  MeldCalendarMeldsList,
  MeldCalendarDetailsCard,
  MeldCalendarListQuickFilterOptions,
  loadCalendarAppliedFilterFromStorage,
};
