var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { create } from "zustand";
import { uuid } from "uuidv4";
import { CalendarRedesignDraftModeToggled, track } from "@pm-app/utils/analytics";
import Features from "@pm-assets/js/common/feature-flags";
import { useCalendarTimeFrameStateStore } from "./timeFrameStore";
const useCalendarDraftModeStateStore = create((set) => ({
  enabled: false,
  modalOpen: false,
  pendingActions: [],
  ignoredRecommendations: [],
  pendingErrors: null,
  actions: {
    addPendingAction: (newAction) => {
      set((state) => {
        let currentActions = [...state.pendingActions];
        if (newAction.type === "cancelAppointment") {
          currentActions = currentActions.filter(
            (action) => action.appointmentId !== newAction.appointmentId || action.meldId !== newAction.meldId
          );
        } else if (newAction.type === "rescheduleAppointment") {
          currentActions = currentActions.filter(
            (action) => action.appointmentId !== newAction.appointmentId || action.meldId !== newAction.meldId
          );
        } else if (newAction.type === "assignMeld") {
          currentActions = currentActions.filter(
            (action) => !(action.type === "assignMeld" && action.meldId === newAction.meldId)
          );
        } else if (newAction.type === "unassignMeld") {
          currentActions = currentActions.filter((action) => !(action.meldId === newAction.meldId));
        }
        return { pendingActions: [...currentActions, __spreadProps(__spreadValues({}, newAction), { uuid: uuid() })] };
      });
    },
    removePendingAction: (uuid2) => set((state) => ({ pendingActions: state.pendingActions.filter((action) => action.uuid !== uuid2) })),
    updatePendingAction: (uuid2, valuesToUpdate) => set((state) => {
      const otherActions = state.pendingActions.filter((action) => action.uuid !== uuid2);
      const actionToChange = state.pendingActions.find((action) => action.uuid === uuid2);
      if (!actionToChange) {
        return { pendingActions: otherActions };
      }
      Object.entries(valuesToUpdate).forEach(([key, value]) => {
        actionToChange[key] = value;
      });
      return { pendingActions: [...otherActions, actionToChange] };
    }),
    addIgnoredRecommendation: (recommendationId) => {
      set((state) => {
        return { ignoredRecommendations: [...state.ignoredRecommendations, recommendationId] };
      });
    },
    removeIgnoredRecommendation: (recommendationId) => set((state) => ({
      ignoredRecommendations: state.ignoredRecommendations.filter((rec) => rec !== recommendationId)
    })),
    resetState: () => set({ enabled: false, pendingActions: [], ignoredRecommendations: [], modalOpen: false, pendingErrors: null }),
    setDraftModeEnabled: (newValue, shouldTrack) => set(() => {
      if (shouldTrack) {
        track(
          CalendarRedesignDraftModeToggled(
            useCalendarTimeFrameStateStore.getState().selectedCalendarTimeFrame,
            newValue
          )
        );
      }
      return { enabled: newValue, pendingActions: [], ignoredRecommendations: [], modalOpen: false };
    }),
    setPendingErrors: (errors) => set({ pendingErrors: errors }),
    toggleModal: (newValue) => set({ modalOpen: newValue })
  }
}));
const useCalendarDraftModeEnabled = () => useCalendarDraftModeStateStore((state) => {
  if (!Features.isDraftSchedulerEnabled()) {
    return false;
  }
  return state.enabled;
});
const useGetCalendarDraftModePendingActions = () => useCalendarDraftModeStateStore((state) => state.pendingActions);
const useGetCalendarDraftModeIgnoredRecommendations = () => useCalendarDraftModeStateStore((state) => state.ignoredRecommendations);
const useGetCalendarDraftModePendingErrors = () => useCalendarDraftModeStateStore((state) => state.pendingErrors);
const useGetCalendarDraftModeModalOpen = () => useCalendarDraftModeStateStore((state) => state.modalOpen);
const useCalendarDraftModeActions = () => useCalendarDraftModeStateStore((state) => state.actions);
const getCalendarDraftModeEnabled = () => {
  if (!Features.isDraftSchedulerEnabled()) {
    return false;
  }
  return useCalendarDraftModeStateStore.getState().enabled;
};
const getCalendarDraftModeActions = () => useCalendarDraftModeStateStore.getState().actions;
const getCalendarDraftModePendingActions = () => useCalendarDraftModeStateStore.getState().pendingActions;
export {
  useCalendarDraftModeStateStore,
  useCalendarDraftModeEnabled,
  useCalendarDraftModeActions,
  useGetCalendarDraftModePendingActions,
  useGetCalendarDraftModePendingErrors,
  useGetCalendarDraftModeIgnoredRecommendations,
  useGetCalendarDraftModeModalOpen,
  getCalendarDraftModeEnabled,
  getCalendarDraftModeActions,
  getCalendarDraftModePendingActions
};
