import { parseISO } from "date-fns";
import { ManagementAgentSerializer } from "../types/api/manager/serializers/serializers";
import { VendorFilterSerializer } from "../types/api/vendor/serializers/serializers";
import { VendorInviteMaintenanceSerializer } from "../types/api/vendor/serializers/vendor_invite_maintenance_serializer";
import { MaintTypes } from "../types/meld";
import { getFullName } from "./agent-utils";
import { getInviteFullName } from "./invite-utils";
import { getMeldInHouseServicers } from "./meld-utils";
import { getAssignedPropertyGroupsByNumber, getVendorDisplayName } from "./vendor-utils";

export const isAssigned = (assignment: { rejected?: string; canceled?: string }) => {
  return !assignment.rejected && !assignment.canceled;
};

export type AssignedInHouseServicerAgent = number | { id: number };

interface GetAssignedAgentsProps<T extends AssignedInHouseServicerAgent> {
  in_house_servicers: Array<{
    agent: T;
  }>;
}

export const getAssignedAgents = <T extends AssignedInHouseServicerAgent>(meld: GetAssignedAgentsProps<T>): T[] => {
  return (getMeldInHouseServicers(meld) || []).map((servicer) => servicer.agent);
};

interface GetAssignedVendorProps<T> {
  vendor_assignment_requests: Array<{
    id: number;
    vendor: T;
    rejected?: string;
    canceled?: string;
  }>;
}

export const getOpenVendorAssignment = <T>(
  meld: GetAssignedVendorProps<T>
): GetAssignedVendorProps<T>["vendor_assignment_requests"][number] | undefined => {
  return meld.vendor_assignment_requests.find((assignment) => isAssigned(assignment));
};

export const getAssignedVendor = <T>(meld: GetAssignedVendorProps<T>): T | undefined => {
  if (meld.vendor_assignment_requests?.length > 0) {
    const openAssignment = getOpenVendorAssignment(meld);
    if (openAssignment) {
      return openAssignment.vendor;
    }
  }
  return;
};

export interface InviteProps {
  id: number;
  canceled?: string;
  created: string;
  invite_group: {
    invite: {
      first_name?: string;
      last_name?: string;
      email?: string;
    };
  };
}

interface GetAssignedInviteProps<T> {
  invite_assignments?: Array<T & InviteProps>;
}

export const getOpenVendorInviteAssignment = <T>(meld: GetAssignedInviteProps<T>): T | undefined => {
  return meld.invite_assignments?.find((assignment) => {
    return !assignment.canceled;
  });
};

export const getAssignedInvite = <T>(meld: GetAssignedInviteProps<T>): T | undefined => {
  const inviteAssignments = meld.invite_assignments;
  if (inviteAssignments && inviteAssignments.length > 0) {
    const openAssignment = getOpenVendorInviteAssignment(meld);
    if (openAssignment) {
      return openAssignment;
    }
  }
  return;
};

export type GetAssignedMaintenanceProps<
  A extends AssignedInHouseServicerAgent,
  V,
  I extends InviteProps
> = GetAssignedAgentsProps<A> & GetAssignedVendorProps<V> & GetAssignedInviteProps<I> & { id: number };

export type GetAssignedMaintenanceReturn<A, V, I> =
  | {
      type: typeof MaintTypes.MANAGEMENT_AGENT;
      in_house_servicers: A[];
      names: string[];
    }
  | {
      type: typeof MaintTypes.VENDOR;
      vendor: V;
      name: string;
    }
  | {
      type: typeof MaintTypes.INVITED_VENDOR;
      invite_assignment: I;
      name: string;
    }
  | undefined;

export const getAssignedMaint = <
  A extends { id: number; first_name?: string; last_name?: string; created?: string },
  V extends { id: number; created?: string; name?: string; email?: string },
  I extends InviteProps
>(
  meld: GetAssignedMaintenanceProps<A, V, I>
): GetAssignedMaintenanceReturn<A, V, I> => {
  const servicers = getAssignedAgents(meld);
  if (servicers.length > 0) {
    return {
      type: MaintTypes.MANAGEMENT_AGENT,
      in_house_servicers: servicers,
      names: servicers.map((servicer) => getFullName(servicer)),
    };
  }
  const vendor = getAssignedVendor(meld);
  if (vendor) {
    return {
      type: MaintTypes.VENDOR,
      vendor,
      name: getVendorDisplayName(vendor),
    };
  }
  const inviteAssignment = getAssignedInvite(meld);
  if (inviteAssignment) {
    return {
      type: MaintTypes.INVITED_VENDOR,
      invite_assignment: inviteAssignment,
      name: getInviteFullName(inviteAssignment.invite_group.invite),
    };
  }
  return;
};

/*
 * Used to get a composite id specifically from the return of `getAssignedMaint`
 */
export const getCompositeIdFromAssignedMaint = <
  A extends { id?: number },
  V extends { id?: number },
  I extends { id?: number }
>(
  maint: NonNullable<GetAssignedMaintenanceReturn<A, V, I>>
): string[] => {
  switch (maint.type) {
    case "ManagementAgent":
      return maint.in_house_servicers
        .map((a) => {
          return a.id ? makeCompositeId(a.id, "agent") : undefined;
        })
        .filter(Boolean) as string[];
    case "Vendor":
      return maint.vendor.id ? [makeCompositeId(maint.vendor.id, "vendor")] : [];
    case "VendorInvite":
      return maint.invite_assignment.id ? [makeCompositeId(maint.invite_assignment.id, "vendor")] : [];
    default:
      return [];
  }
};

export type CompositeId = `${1 | 2 | 3}-${number | string}`;

const CompositeIdPrefixes = {
  agent: "2",
  vendor: "1",
  vendorInvite: "3",
} as const;

/** the backend attaches composite ids to the techs/vendors/vendor invites with
 * prefixes as follows
 * '1-' - vendor
 * '2-' - management agent (in-house tech)
 * '3-' - vendor invite
 */
export const isMaintenanceVendor = (maint: { composite_id: string }) =>
  maint.composite_id.startsWith(CompositeIdPrefixes.vendor);
export const isMaintenanceManagementAgent = (maint: { composite_id: string }) =>
  maint.composite_id.startsWith(CompositeIdPrefixes.agent);
export const isMaintenanceVendorInvite = (maint: { composite_id: string }) =>
  maint.composite_id.startsWith(CompositeIdPrefixes.vendorInvite);

export const makeCompositeId = (id: number | string, type: "agent" | "vendor" | "vendorInvite"): CompositeId =>
  `${CompositeIdPrefixes[type]}-${id}`;

// note that we don't actualy validate that the second half is a parsable number
export const isValidCompositeId = (value: string): value is CompositeId => {
  const compositeArr = value.split("-");
  if (compositeArr.length !== 2) {
    return false;
  }

  const first = compositeArr[0];
  if (
    first === CompositeIdPrefixes.agent ||
    first === CompositeIdPrefixes.vendor ||
    first === CompositeIdPrefixes.vendorInvite
  ) {
    return true;
  }
  return false;
};

/* utility function to filter maintenance property groups
 * @example
 * const filterFunction  = getFilterByPropertyGroups(property_groups)
 * allMaintenance.filter(filterFunction)
 *
 */
export const getFilterByPropertyGroups = (
  property_groups: number[]
): ((maint: VendorInviteMaintenanceSerializer | VendorFilterSerializer | ManagementAgentSerializer) => boolean) => {
  const validPropertyGroupsMap: Record<number, boolean> = {};
  for (const pg of property_groups) {
    validPropertyGroupsMap[pg] = true;
  }
  return (maint) => {
    switch (maint.type) {
      case MaintTypes.MANAGEMENT_AGENT:
        return !property_groups.length || maint.property_groups.some((pg) => validPropertyGroupsMap[pg]);
      case MaintTypes.VENDOR:
        return (
          !property_groups.length ||
          getAssignedPropertyGroupsByNumber(property_groups, maint.excluded_property_groups).some(
            (pg) => validPropertyGroupsMap[pg]
          )
        );
      case MaintTypes.INVITED_VENDOR:
        return true;
      default:
        return false;
    }
  };
};

export const isWorkersCompValid = (selectedMaintenance: VendorFilterSerializer) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const workersCompensationExpiration = selectedMaintenance.workers_compensation_expiration
    ? parseISO(selectedMaintenance.workers_compensation_expiration)
    : null;

  return !workersCompensationExpiration || workersCompensationExpiration >= today;
};

export const isLiabilityInsuranceValid = (selectedMaintenance: VendorFilterSerializer) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const generalLiabilityInsuranceExpiration = selectedMaintenance.general_liability_insurance_expiration
    ? parseISO(selectedMaintenance.general_liability_insurance_expiration)
    : null;

  return !generalLiabilityInsuranceExpiration || generalLiabilityInsuranceExpiration >= today;
};

export const isAppfolioComplianceValid = (selectedMaintenance: VendorFilterSerializer) => {
  return selectedMaintenance.vendor_managements[0].appfolio_compliance;
};
