import { create } from "zustand";
import { uuid } from "uuidv4";

import { CalendarRedesignDraftModeToggled, track } from "@pm-app/utils/analytics";
import Features from "@pm-assets/js/common/feature-flags";
import { useCalendarTimeFrameStateStore } from "./timeFrameStore";
import { DistributiveOmit } from "@pm-frontend/shared/types/utils";
import { ManagementAgentSerializer } from "@pm-frontend/shared/types/api/manager/serializers/serializers";
import { VendorFilterSerializer } from "@pm-frontend/shared/types/api/vendor/serializers/serializers";

type CalendarDraftPendingError = {
  uuid: string;
  message: string;
  status: "SUCCESS" | "FAILURE";
  event: CalendarDraftPendingActions;
};

type CalendarDraftPendingActions = {
  // used in the schedule modal
  date: Date | null;
  // used to connect errors from backend and frontend
  uuid: string;
  personaIds: number[];
  recommendationId: number | undefined;
  personaType: "agent" | "vendor" | null;
} & (
  | {
      type: "cancelAppointment";
      appointmentId: number;
      meldId: number;
      addressText?: never;
    }
  | {
      type: "rescheduleAppointment";
      appointmentId: number;
      meldId: number;
      startTime: Date;
      endTime: Date;
      description: string;
      addressText: string;
    }
  | ({
      type: "assignMeld";
      meldId: number;
      appointmentId?: never;
    } & (
      | {
          assigneeType: "agent";
          maintenance: ManagementAgentSerializer[];
        }
      | {
          assigneeType: "vendor";
          maintenance: VendorFilterSerializer[];
        }
    ))
  | {
      type: "unassignMeld";
      meldId: number;
      maintenance: [];
      appointmentId?: never;
    }
);

interface CalendarDraftModeState {
  enabled: boolean;
  pendingActions: CalendarDraftPendingActions[];
  ignoredRecommendations: number[];
  pendingErrors: CalendarDraftPendingError[] | null;
  modalOpen: boolean;
  actions: {
    addPendingAction: (newAction: DistributiveOmit<CalendarDraftPendingActions, "uuid">) => void;
    removePendingAction: (uuid: string) => void;
    updatePendingAction: (uuid: string, valuesToUpdate: Partial<CalendarDraftPendingActions>) => void;
    addIgnoredRecommendation: (recommendationId: number) => void;
    removeIgnoredRecommendation: (recommendationId: number) => void;
    resetState: VoidFunction;
    setDraftModeEnabled: (newValue: boolean, shouldTrack?: boolean) => void;
    setPendingErrors: (errors: CalendarDraftPendingError[] | null) => void;
    toggleModal: (newValue: boolean) => void;
  };
}

// Do not export, access via custom hooks instead
const useCalendarDraftModeStateStore = create<CalendarDraftModeState>((set) => ({
  enabled: false,
  modalOpen: false,
  pendingActions: [],
  ignoredRecommendations: [],
  pendingErrors: null,
  actions: {
    addPendingAction: (newAction) => {
      set((state) => {
        let currentActions = [...state.pendingActions];
        // reconcile incoming actions with current ones in list
        if (newAction.type === "cancelAppointment") {
          // remove any pending actions on that appointment
          currentActions = currentActions.filter(
            (action) => action.appointmentId !== newAction.appointmentId || action.meldId !== newAction.meldId
          );
        } else if (newAction.type === "rescheduleAppointment") {
          // remove any pending actions on that appointment
          currentActions = currentActions.filter(
            (action) => action.appointmentId !== newAction.appointmentId || action.meldId !== newAction.meldId
          );
        } else if (newAction.type === "assignMeld") {
          // remove any pending actions on that appointment
          currentActions = currentActions.filter(
            (action) => !(action.type === "assignMeld" && action.meldId === newAction.meldId)
          );
        } else if (newAction.type === "unassignMeld") {
          // remove any pending actions on the Meld
          currentActions = currentActions.filter((action) => !(action.meldId === newAction.meldId));
        }
        return { pendingActions: [...currentActions, { ...newAction, uuid: uuid() }] };
      });
    },
    removePendingAction: (uuid) =>
      set((state) => ({ pendingActions: state.pendingActions.filter((action) => action.uuid !== uuid) })),
    updatePendingAction: (uuid, valuesToUpdate) =>
      set((state) => {
        const otherActions = state.pendingActions.filter((action) => action.uuid !== uuid);
        const actionToChange = state.pendingActions.find((action) => action.uuid === uuid);
        if (!actionToChange) {
          return { pendingActions: otherActions };
        }
        Object.entries(valuesToUpdate).forEach(([key, value]) => {
          // @ts-expect-error we allow a merge here
          actionToChange[key] = value;
        });

        return { pendingActions: [...otherActions, actionToChange] };
      }),
    addIgnoredRecommendation: (recommendationId) => {
      set((state) => {
        return { ignoredRecommendations: [...state.ignoredRecommendations, recommendationId] };
      });
    },
    removeIgnoredRecommendation: (recommendationId) =>
      set((state) => ({
        ignoredRecommendations: state.ignoredRecommendations.filter((rec) => rec !== recommendationId),
      })),
    resetState: () =>
      set({ enabled: false, pendingActions: [], ignoredRecommendations: [], modalOpen: false, pendingErrors: null }),
    setDraftModeEnabled: (newValue, shouldTrack) =>
      set(() => {
        if (shouldTrack) {
          track(
            CalendarRedesignDraftModeToggled(
              useCalendarTimeFrameStateStore.getState().selectedCalendarTimeFrame,
              newValue
            )
          );
        }
        return { enabled: newValue, pendingActions: [], ignoredRecommendations: [], modalOpen: false };
      }),
    setPendingErrors: (errors) => set({ pendingErrors: errors }),
    toggleModal: (newValue) => set({ modalOpen: newValue }),
  },
}));

const useCalendarDraftModeEnabled = () =>
  useCalendarDraftModeStateStore((state) => {
    if (!Features.isDraftSchedulerEnabled()) {
      return false;
    }
    return state.enabled;
  });

const useGetCalendarDraftModePendingActions = () => useCalendarDraftModeStateStore((state) => state.pendingActions);
const useGetCalendarDraftModeIgnoredRecommendations = () =>
  useCalendarDraftModeStateStore((state) => state.ignoredRecommendations);
const useGetCalendarDraftModePendingErrors = () => useCalendarDraftModeStateStore((state) => state.pendingErrors);
const useGetCalendarDraftModeModalOpen = () => useCalendarDraftModeStateStore((state) => state.modalOpen);
const useCalendarDraftModeActions = () => useCalendarDraftModeStateStore((state) => state.actions);

// non-hook/non-reactive functions to access the state in event handlers
const getCalendarDraftModeEnabled = () => {
  if (!Features.isDraftSchedulerEnabled()) {
    return false;
  }
  return useCalendarDraftModeStateStore.getState().enabled;
};

const getCalendarDraftModeActions = () => useCalendarDraftModeStateStore.getState().actions;
const getCalendarDraftModePendingActions = () => useCalendarDraftModeStateStore.getState().pendingActions;

export {
  useCalendarDraftModeStateStore,
  useCalendarDraftModeEnabled,
  useCalendarDraftModeActions,
  useGetCalendarDraftModePendingActions,
  useGetCalendarDraftModePendingErrors,
  useGetCalendarDraftModeIgnoredRecommendations,
  useGetCalendarDraftModeModalOpen,
  getCalendarDraftModeEnabled,
  getCalendarDraftModeActions,
  getCalendarDraftModePendingActions,
  CalendarDraftPendingActions,
};
