import React from "react";
import { EuiButtonEmpty } from "@elastic/eui";
import { CSSObject } from "@emotion/css";
import { Link } from "react-router-dom";

import { BorderColor, colors } from "@pm-frontend/styles";
import { PmButtonProps } from "./PmFilledButton";

type PmEmptyButtonProps = {
  text: React.ReactNode;
  color?: "primary" | "danger" | "accent" | "input";
  "data-testid"?: string;
  textSize?: string;
  fontWeight?: number;
  hasBorder?: boolean;
  padding?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
} & PmButtonProps;

const backgroundColorMap: Record<Required<PmEmptyButtonProps>["color"], string> = {
  primary: colors.brand.white,
  danger: colors.brand.white,
  accent: colors.brand.white,
  // meant to emulate EUI input styling with a button
  // not actually a style-guide color
  input: "#fbfcfd",
};

const borderColorMap: Record<Required<PmEmptyButtonProps>["color"], string> = {
  primary: colors.brand.meldBlue,
  danger: colors.interface.red.default,
  accent: colors.neutrals.gray500,
  // meant to emulate EUI input styling with a button
  // not actually a style-guide color
  input: BorderColor,
};

const textColorMap: Record<Required<PmEmptyButtonProps>["color"], string> = {
  primary: colors.brand.meldBlue,
  danger: colors.interface.red.default,
  accent: colors.neutrals.gray500,
  // meant to emulate EUI input styling with a button
  // not actually a style-guide color
  input: "#333333",
};

const cssButton: CSSObject = {
  "&.emptyButton:hover": {
    backgroundColor: `${colors.brand.lightBlue} !important`,
  },
};

const PmEmptyButton = ({
  text,
  textSize = "16px",
  fontWeight = 400,
  padding = "9.5px 9.5px",
  color = "primary",
  "data-testid": dataTestId,
  onClick,
  href,
  formId,
  internalLink,
  hasBorder = false,
  isLoading = false,
  isDisabled = false,
  linkToProps,
}: PmEmptyButtonProps) => {
  const colorChoice = isDisabled ? "accent" : color;

  const style: React.CSSProperties = {
    fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans- serif',
    backgroundColor: backgroundColorMap[colorChoice],
    border: hasBorder ? `1px solid ${borderColorMap[colorChoice]}` : `0px`,
    borderRadius: "6px",
    textDecoration: "none",
    height: "fit-content",
    padding,
  };

  const textProps: { style: React.CSSProperties } = {
    style: {
      color: textColorMap[colorChoice],
      fontWeight,
      fontSize: textSize,
      lineHeight: "21px",
      textAlign: "center",
    },
  };

  const contentProps: { style: React.CSSProperties } = {
    style: {
      height: "fit-content",
      padding: "0px",
    },
  };
  const defaultProps = {
    className: "emptyButton",
    style,
    textProps,
    contentProps,
    "data-testid": dataTestId,
    css: isDisabled ? "" : cssButton,
    isLoading,
    onClick,
  };

  if (href) {
    if (internalLink && !isDisabled) {
      return (
        <Link to={href}>
          <EuiButtonEmpty {...defaultProps}>{text}</EuiButtonEmpty>
        </Link>
      );
    } else {
      return (
        <EuiButtonEmpty {...defaultProps} href={href} target="_blank" isDisabled={isDisabled}>
          {text}
        </EuiButtonEmpty>
      );
    }
  } else if (formId) {
    return (
      <EuiButtonEmpty {...defaultProps} form={formId} type="submit" isDisabled={isDisabled}>
        {text}
      </EuiButtonEmpty>
    );
  } else if (linkToProps) {
    return (
      <Link to={linkToProps}>
        <EuiButtonEmpty {...defaultProps} isDisabled={isDisabled}>
          {text}
        </EuiButtonEmpty>
      </Link>
    );
  } else {
    return (
      <EuiButtonEmpty {...defaultProps} isDisabled={isDisabled}>
        {text}
      </EuiButtonEmpty>
    );
  }
};

export { PmEmptyButton, PmEmptyButtonProps };
