import React from "react";
import { css } from "@emotion/css";
import moment, { isMoment, Moment } from "moment";
import { format } from "date-fns";

import { colors } from "@pm-frontend/styles";

const nativeDateInputStyles: React.CSSProperties = {
  padding: "6px",
  color: colors.neutrals.gray800,
  borderRadius: "6px",
  border: `1px solid ${colors.neutrals.gray200}`,
  width: "100%",
};

const mobileDatePickerCSS = css`
  & {
    display: flex;
    flex-grow: 1;
  }

  & > input.mobile-date-input {
    width: 100%;
  }
  & > input.boldMobileDateInputText {
    font-weight: 700;
  }
`;

interface MobileDateTimePickerProps {
  type: "date" | "time";
  value: string | Moment | Date | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
  isHoursEditable?: boolean;
  additionalClassName?: "boldMobileDateInputText";
}

// we'd like to make it easier on consumers of this component by allowing
// different types to be passed in
const getInputValue = (value: MobileDateTimePickerProps["value"], type: MobileDateTimePickerProps["type"]): string => {
  if (typeof value === "string") {
    return value;
  } else if (isMoment(value)) {
    if (type === "date") {
      return moment(value).format("YYYY-MM-DD");
    } else if (type === "time") {
      return moment(value).format("HH:mm");
    }
  } else if (value instanceof Date) {
    if (type === "date") {
      return format(value, "yyyy-MM-dd");
    } else if (type === "time") {
      return format(value, "HH:mm");
    }
  }
  return "";
};

/**
 * Eui date pickers often gives us issues on mobile, and we'd prefer to use the
 * native OS datepicker, so we use a regular '<input>'
 */
const MobileDateTimePicker = ({
  value,
  type,
  onChange,
  isHoursEditable,
  pattern,
  additionalClassName,
}: MobileDateTimePickerProps) => {
  const _pattern = pattern || "d{2}-d{2}-d{4}";
  return (
    <div className={mobileDatePickerCSS}>
      <input
        className={additionalClassName ? `mobile-date-input ${additionalClassName}` : `mobile-date-input`}
        type={type}
        value={getInputValue(value, type)}
        pattern={type === "date" ? _pattern : undefined}
        onChange={onChange}
        style={nativeDateInputStyles}
        disabled={isHoursEditable}
      />
    </div>
  );
};

export { MobileDateTimePicker, MobileDateTimePickerProps };
